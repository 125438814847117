import { formatJSONReadable } from '../pages/chat/helpers';
import { CaseDraft } from '../store/types';

export const caseCreatedText = (caseId: number) => `Case created successfully. Case id: ${caseId}`;

export const sessionExpiredText = 'Your login session has expired.';

export const genericCaseErrorText = 'Failed to create a case. Please try again.';

export const genericErrorText = 'Failed to get chat response. Please try again.';

export const caseRegistrationConfirmationText = (caseContent: CaseDraft) =>
  `This is a case that I can register in Synergi Life for you: ${formatJSONReadable(caseContent)} \n\nIs it correct?`;
