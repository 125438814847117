import { Outlet } from 'react-router-dom';
import { MdBugReport, MdDelete } from 'react-icons/md';
import { FaSignOutAlt } from 'react-icons/fa';
import { Button, LinkButton } from '../common';
import styles from './Layout.module.css';
import { clearChat, selectIsClearingAnswersDisabled } from '../../store/appSlice';
import { MouseEventHandler } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAuth } from 'react-oidc-context';
import { oidcClientId, oidcServiceUrl, reportBugUrl } from '../../infrastructure/config';
import { useVeracity } from '../../infrastructure/veracity';
import { ensureAuthenticated } from '../../auth/helpers';
import { ErrorPage } from '../error/ErrorPage';

const Layout = () => {
  const auth = useAuth();
  const veracity = useVeracity();

  ensureAuthenticated(auth);

  const disabled = useAppSelector(selectIsClearingAnswersDisabled);
  const dispatch = useAppDispatch();

  const onClearChat: MouseEventHandler = () => dispatch(clearChat());

  if (veracity.policyValidationError) {
    return <ErrorPage>{veracity.policyValidationError}</ErrorPage>;
  }

  if (!auth.isAuthenticated || !veracity.isPolicyValidated) {
    return null;
  }

  const logout = () => {
    sessionStorage.removeItem(`oidc.user:${oidcServiceUrl}:${oidcClientId}`);
    auth.signoutRedirect({ id_token_hint: auth?.user?.id_token });
  };

  return (
    <div className={styles.layout}>
      <header className={styles.header} role={'banner'}>
        <div className={styles.headerContainer}>
          <div className={styles.headerLogoContainer}>
            <Button
              className={styles.headerTitleContainer}
              disabled={disabled}
              onClick={onClearChat}
            >
              <h3 className={styles.headerTitle}>Synergi Life AI</h3>
            </Button>
          </div>
          <nav>
            <ul className={styles.headerNavList}>
              <li>
                <div>
                  <Button
                    className={styles.headerButtonIcon}
                    icon={<MdDelete size={20} />}
                    disabled={disabled}
                    onClick={onClearChat}
                  >
                    <span className={styles.headerButtonText}>Clear chat</span>
                  </Button>
                </div>
              </li>
              {!!reportBugUrl && (
                <>
                  <li>
                    <div>
                      <LinkButton
                        className={styles.headerButtonIcon}
                        icon={<MdBugReport size={18} />}
                        href={reportBugUrl}
                      >
                        <span className={styles.headerButtonText}>Report a bug</span>
                      </LinkButton>
                    </div>
                  </li>
                </>
              )}
              <li>
                <div>
                  <Button
                    className={styles.headerButtonIcon}
                    icon={<FaSignOutAlt size={18} />}
                    onClick={logout}
                  >
                    <span className={styles.headerButtonText}>Sign out</span>
                  </Button>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <Outlet />
    </div>
  );
};

export default Layout;
