import { useEffect } from 'react';
import { AuthContextProps } from 'react-oidc-context';
import { oidcClientId, oidcServiceUrl } from '../infrastructure/config';

export const ensureAuthenticated = (auth: AuthContextProps) => {
  useEffect(() => {
    if (!auth?.isAuthenticated && !auth?.isLoading) {
      !auth.error && auth.signinRedirect();
    }
  }, [auth, auth?.isAuthenticated, auth?.isLoading, auth?.error]);
};

export const triggerSigninRedirect = (auth: AuthContextProps) => () => {
  if (!auth?.isLoading) {
    sessionStorage.removeItem(`oidc.user:${oidcServiceUrl}:${oidcClientId}`);
    auth.signinRedirect();
  }
};
