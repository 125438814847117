import { Stack, StackItem } from '../../pages/common';
import DOMPurify from 'dompurify';
import { useAuth } from 'react-oidc-context';

import styles from './Answer.module.css';

import { ChatAnswer, ChatAnswerType } from '../../store/types';
import { triggerSigninRedirect } from '../../auth/helpers';

const SYNERGI_LIFE_CASE_URL = import.meta.env.VITE_SYNERGI_LIFE_CASE_URL;

interface Props {
  answer: ChatAnswer;
}

export const Answer = ({ answer }: Props) => {
  const auth = useAuth();
  const messageContent = answer.content ?? '';
  const sanitizedAnswer = DOMPurify.sanitize(messageContent, {
    ALLOWED_TAGS: ['em', 'strong', 'code', 'pre'],
    ALLOWED_ATTR: [],
  })
    .replace(/\*\*/g, '') // Remove remaining **
    .replace(/`/g, ''); // Remove any remaining ` backticks
  return (
    <Stack className={styles.answerContainer}>
      <StackItem grow>
        <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswer }} />

        {answer.type === ChatAnswerType.caseCreated && !!SYNERGI_LIFE_CASE_URL && (
          <a href={SYNERGI_LIFE_CASE_URL! + answer.caseId} target="_blank" rel="noreferrer">
            Click here to open your case in Synergi Life
          </a>
        )}

        {answer.type === ChatAnswerType.unauthorized && (
          <a className={styles.link} onClick={triggerSigninRedirect(auth)}>
            Click here to login again
          </a>
        )}
      </StackItem>
    </Stack>
  );
};
