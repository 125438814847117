import { updateChatItem } from '../store/appSlice';
import { AppDispatch } from '../store/store';
import readNDJSONStream from 'ndjson-readablestream';
import {
  caseCreatedText,
  caseRegistrationConfirmationText,
  genericCaseErrorText,
  genericErrorText,
  sessionExpiredText,
} from './constants';
import { HttpStatus } from './http';
import {
  ChatResponseCase,
  ChatResponseContentStream,
  ChatResponseDraft,
  ChatResponse,
  CaseCreationStatus,
} from './types';
import { ChatAnswer, ChatAnswerType } from '../store/types';

export const handleResponse =
  (dispatch: AppDispatch) =>
  async (response: Response, id: number, question: string): Promise<void> => {
    try {
      let answer = '';
      let isStreaming = true;

      for await (const untypedChatResponse of readNDJSONStream(response.body!)) {
        if (!isStreaming) {
          continue;
        }

        const chatResponse = untypedChatResponse as ChatResponse;

        if (hasRegularResponse(chatResponse)) {
          answer = await handleRegularResponse(dispatch, chatResponse, id, question, answer);
        } else if (hasCaseCreationResult(chatResponse)) {
          handleCaseCreationResult(dispatch, chatResponse, response.status, id, question, answer);
          isStreaming = false;
        } else if (hasDraftCase(chatResponse)) {
          handleDraftCaseResponse(dispatch, chatResponse, id, question, answer);
          isStreaming = false;
        } else if (hasErrorResponse(chatResponse)) {
          handleErrorResponse(dispatch, id, question);
          isStreaming = false;
        }
      }

      if (isStreaming) {
        const newChatAnswer: ChatAnswer =
          !!answer && response.status === HttpStatus.OK
            ? {
                content: answer,
                type: ChatAnswerType.ok,
              }
            : {
                content: genericErrorText,
                type: ChatAnswerType.error,
              };

        dispatch(
          updateChatItem({
            id,
            question,
            answer: newChatAnswer,
          })
        );
      }
    } catch (err) {
      updateChatItem({
        id,
        question,
        answer: { content: genericErrorText, type: ChatAnswerType.error },
      });
    }
  };

const hasRegularResponse = (chatResponse: ChatResponse) => !!chatResponse?.content;

const handleRegularResponse = async (
  dispatch: AppDispatch,
  chatResponse: ChatResponseContentStream,
  id: number,
  question: string,
  answer: string
) => {
  const content = (answer + chatResponse.content)
    .replace(/^ +/gm, (match) => match.replace(/ /g, '&nbsp;')) // Replace leading spaces with &nbsp;
    .replace(/</g, '&lt;') // Replace all < with &lt;
    .replace(/>/g, '&gt;') // Replace all > with &gt;
    .replace(/&lt;(\/?em|\/?strong|\/?pre|\/?code)&gt;/g, '<$1>') // Restore allowed tags
    .replace(/\*\*([^*\n]+)\*\*/g, '<strong>$1</strong>') // Replace ** with <strong>
    .replace(/\*([^*\n]+)\*/g, '<em>$1</em>') // Replace * with <em>
    .replace(/`([^`\n]+)`/g, '<code>$1</code>') // Replace ` with <code>
    .replace(/```.*\n([\s\S]*?)\n```.*\n/g, '<pre><code>$1</code></pre>'); // replace code blocks

  const newChatAnswer: ChatAnswer = {
    content,
    type: ChatAnswerType.streaming,
  };

  await new Promise<void>((resolve) => {
    setTimeout(() => {
      dispatch(updateChatItem({ id, question, answer: newChatAnswer }));
      resolve();
    }, 33);
  });

  return content;
};

const hasCaseCreationResult = (chatResponse: ChatResponse) => !!chatResponse?.caseCreationStatus;

const handleCaseCreationResult = (
  dispatch: AppDispatch,
  chatResponse: ChatResponseCase,
  responseStatus: number,
  id: number,
  question: string,
  answer: string
) => {
  const newContent =
    chatResponse.caseCreationStatus === CaseCreationStatus.created
      ? caseCreatedText(chatResponse.caseId as number)
      : responseStatus === HttpStatus.Unauthorized
        ? sessionExpiredText
        : genericCaseErrorText;

  const newType: ChatAnswerType =
    chatResponse.caseCreationStatus === CaseCreationStatus.created
      ? ChatAnswerType.caseCreated
      : responseStatus === HttpStatus.Unauthorized
        ? ChatAnswerType.unauthorized
        : ChatAnswerType.error;

  const newChatAnswer: ChatAnswer = {
    content: answer + newContent,
    caseId: chatResponse.caseId,
    type: newType,
  };

  dispatch(updateChatItem({ id, question, answer: newChatAnswer }));
};

const hasDraftCase = (chatResponse: ChatResponse) => !!chatResponse?.case;

const handleDraftCaseResponse = (
  dispatch: AppDispatch,
  chatResponse: ChatResponseDraft,
  id: number,
  question: string,
  answer: string
) => {
  const newContent = caseRegistrationConfirmationText(chatResponse.case);

  const newChatAnswer: ChatAnswer = {
    content: answer + newContent,
    draft: chatResponse.case,
    type: ChatAnswerType.ok,
  };

  dispatch(updateChatItem({ id, question, answer: newChatAnswer }));
};

const hasErrorResponse = (chatResponse: ChatResponse) =>
  chatResponse.error || chatResponse.errors || typeof chatResponse === 'string';

const handleErrorResponse = async (dispatch: AppDispatch, id: number, question: string) => {
  const newChatAnswer: ChatAnswer = {
    content: genericErrorText,
    type: ChatAnswerType.error,
  };

  dispatch(updateChatItem({ id, question, answer: newChatAnswer }));
};
