import { ChatRequest } from './types';

const BACKEND_URI = import.meta.env.VITE_CHAT_URL;

const getHeaders = (accessToken?: string): Record<string, string> => ({
  'Content-Type': 'application/json',
  ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
});

export async function postChatApi(
  request: ChatRequest,
  parameters?: RequestParameters
): Promise<Response> {
  return await fetch(`${BACKEND_URI}/chat`, {
    method: 'POST',
    headers: getHeaders(parameters?.accessToken),
    body: JSON.stringify(request),
  });
}

export interface RequestParameters {
  accessToken?: string;
}
