import { AuthProviderProps } from 'react-oidc-context';
import {
  oidcServiceUrl,
  oidcClientId,
  oidcRedirectUri,
  oidcPostLogoutRedirectUri,
  oidcScope,
  oidcDefaultProviderId,
} from '../infrastructure/config';
import { WebStorageStateStore } from 'oidc-client-ts';

export const oidcConfig: AuthProviderProps = {
  authority: oidcServiceUrl,
  client_id: oidcClientId,
  redirect_uri: oidcRedirectUri,
  post_logout_redirect_uri: oidcPostLogoutRedirectUri,
  scope: oidcScope,
  acr_values: oidcDefaultProviderId?.length > 0 ? `idp:${oidcDefaultProviderId}` : undefined,
  response_type: 'code',
  response_mode: 'query',
  monitorSession: false,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};
