export interface ChatItem {
  id: number;
  question: string;
  answer: ChatAnswer;
}

export interface ChatAnswer {
  content: string;
  caseId?: number;
  draft?: CaseDraft;
  type: ChatAnswerType;
}

export enum ChatAnswerType {
  ok = 'OK',
  loading = 'LOADING',
  streaming = 'STREAMING',
  caseCreated = 'CASE_CREATED',
  unauthorized = 'UNAUTHORIZED',
  error = 'ERROR',
}

export interface CaseDraft {
  caseType: string;
  riskArea: string;
  dateTime: string;
  location: string;
  responsibleUnit: string;
  caseCategorisation: string;
  title: string;
  description: string;
}
