import { useState, useCallback, useEffect } from 'react';
import { oidcRedirectUri, oidcServiceUrl } from './config';
import { useAuth } from 'react-oidc-context';
import { redirect } from './redirect';

interface PolicyResponse {
  accepted?: boolean;
  policyConsentUrl?: string;
}

interface UseVeracityContext {
  isPolicyValidated: boolean;
  policyValidationError: string | undefined;
}

const veracityBrokerUrl = 'https://veracitybroker.synergilife.dnv.com/policy/validate';
const veracityPolicyUrl = 'https://id.veracity.com';
const veracityHostname = 'veracity.com';

const verificationError = 'Failed to verify that Veracity Terms of Service have been accepted.';
const navigationError = 'Failed to navigate to Veracity Terms of Service.';

export function useVeracity(): UseVeracityContext {
  const auth = useAuth();
  const isAuthenticated = auth.isAuthenticated && !auth.isLoading && !auth.error;

  const [isPolicyValidated, setIsPolicyValidated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [policyValidationError, setPolicyValidationError] = useState<string | undefined>();

  useEffect(() => {
    if (isAuthenticated && !isLoading && !isPolicyValidated && !policyValidationError) {
      if (!oidcServiceUrl.includes(veracityHostname)) {
        setIsPolicyValidated(true);
        return;
      }

      const accessToken = auth?.user?.access_token ?? '';
      validatePolicy(accessToken);
    }
  }, [isAuthenticated, isLoading, isPolicyValidated]);

  const validatePolicy = useCallback(async (accessToken: string) => {
    setIsLoading(true);

    try {
      const url = new URL(veracityBrokerUrl);

      url.searchParams.append('api-version', '2.0');
      url.searchParams.append('redirectUri', oidcRedirectUri);

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        },
      });

      if (!response.ok) {
        setPolicyValidationError(verificationError);
      }

      const data: PolicyResponse = await response.json();

      if (data.accepted) {
        setIsPolicyValidated(true);
        setIsLoading(false);
        return;
      }

      if (!data.policyConsentUrl || new URL(data.policyConsentUrl).protocol.length === 0) {
        setPolicyValidationError(navigationError);
        return;
      }

      const result = redirect(data.policyConsentUrl, veracityPolicyUrl);

      if (!result) {
        setPolicyValidationError(navigationError);
        return;
      }
    } catch (error) {
      setPolicyValidationError(verificationError);
    }
  }, []);

  return { isPolicyValidated, policyValidationError };
}
